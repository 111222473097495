import {VueSlideToggle} from "vue-slide-toggle";

export default {
  name: "accordion",
  props: {
    faq: {
      type: Object,
      default: () => {
      }
    }
  },
  components: {
    VueSlideToggle
  },
  data() {
    return {
      show: false,
    }
  },

}