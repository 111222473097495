import {mapActions, mapGetters} from "vuex";
import accordion from './components/accordion/index.vue'

export default {
  name: "faqs",
  components: {
    accordion
  },
  data() {
    return {}
  },
  created() {
    this.fetchFaq()
  },
  computed: {
    ...mapGetters({
      faq:'pages/faq'
    })
  },
  methods: {
    ...mapActions({
      fetchFaq: 'pages/GET_FAQ'
    })
  }

}